<template>
  <div>
    <div class="d-flex flex-column">
      <p
        tabindex="0"
        class="mt-8 primary--text text-center text-h4 font-weight-bold"
      >
        Transaction cancelled
      </p>

      <div class="pa-10 pa-sm-3 d-flex justify-center">
        <v-img v-if="this.$store.state.theme === 'payagent'"
          max-width="200"
          class="svg-image mt-sm-5"
          src="../../assets/PayAgentBranding/undraw_payment_error.svg"
          contain
        />
        <v-img v-else
          max-width="200"
          class="svg-image mt-sm-5"
          src="../../assets/CalmonyPayBranding/undraw_payment_error.svg"
          contain
        />
      </div>

      <TryAgainButton />

      <BankDetails tabindex="0" class="mt-8" />

      <ContactDetails tabindex="0" />
    </div>
  </div>
</template>

<script>
import BankDetails from '@/components/BankDetails.vue';
import ContactDetails from '@/components/ContactDetails.vue';
import TryAgainButton from '../../components/TryAgainButton.vue';

export default {
  components: {
    BankDetails,
    ContactDetails,
    TryAgainButton
  },
  name: 'PaymentCancelled'
}
</script>